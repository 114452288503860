import axios from 'axios';
import Qs from 'qs';
import store from 'store'

// -------------------------------

export default class ApiService {

	url = '';
	newAxios = null;

	constructor(url) {
		this.url = url ?? process.env.VUE_APP_AWS_COGNITO_DOMAIN;
		this.instance();
		this.interceptorsReq();
		this.interceptorsRes();

	}

	instance() {
		this.newAxios = axios.create({
			baseURL: this.url
		});
	}

	query(resource, params) {
		return this.newAxios.get(`/${resource}`, {
			params: params
		}).catch(error => {
			console.error(`[AMV] ApiService query ${error}`);
		});
	}

	get(resource, slug = "") {
		return this.newAxios.get(`/${resource}/${slug}`).catch(error => {
			console.error(`[AMV] ApiService get ${error}`);
		});
	}

	async post(resource, params) {
		return await this.newAxios.post(`/${resource}`, params);
	}

	async update(resource, slug, params) {
		return await this.newAxios.put(`/${resource}/${slug}`, params);
	}

	async put(resource, params) {
		return await this.newAxios.put(`/${resource}`, params);
	}

	async delete(resource, slug) {
		return await this.newAxios.delete(`/${resource}/${slug}`);
	}

	async deleteWithData(resource, data) {
		return await this.newAxios.delete(`/${resource}`, {data});
	}

	// Configurazione per ogni richiesta che passa da axios
	interceptorsReq() {
		this.newAxios.interceptors.request.use(async (config) => {
			config.paramsSerializer = (params) => {
				return Qs.stringify(params, {
					arrayFormat: 'brackets'
				})
			};

            // Non includere nel header delle chiamate alle route di auth il token
            config.headers['Authorization'] = `Bearer ${store.getters['auth/getUser'].signInUserSession.accessToken.jwtToken}`;

			config.headers['accept-language'] = store.state.lang;

			return config
		})
	}

	// Configurazione per ogni risposta che proviene da axios
	interceptorsRes() {
		this.newAxios.interceptors.response.use(function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response;
		}, function (error) {
			try {
				if (error.response) {

					return error.response;

				}
			} catch (error) {
				// Something happened in setting up the request that triggered an Error
				throw `ApiService response ${error}`;
			}
		});
	}
}

export const api = new ApiService();