<template>
  <div id="checkUser"></div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router'
import {api} from '../plugins/axios';

const router = useRouter();

onMounted( async () => {
  const res = await api.post('user/check', {});

  if(res) {
    router.push({path: '/'})
  }
})

</script>